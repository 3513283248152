import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../_services';
import { OtpDialogComponent } from './otp-dialog/otp-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'app/_services/notification.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  data = '';
  error = '';

  otpDialogRef: MatDialogRef<OtpDialogComponent>;

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService, public dialog: MatDialog,
    public notificationService: NotificationService) {
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    this.resetPasswordForm = this.formBuilder.group({
      email: ['', Validators.required]
    });
  }

  get f() { return this.resetPasswordForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.loading = true;

    this.authenticationService.otpPassword(this.f.email.value).subscribe((response) => {
      if (response.status == "TPDCS") {
        this.loading = false;
        this.otpDialogRef = this.dialog.open(OtpDialogComponent, {
          // minHeight:'400px',
          minWidth: '500px',
          data: {
            'email': this.f.email.value
          }
        });

        this.otpDialogRef.afterClosed().subscribe(() => {
        }
        );
      } else {
        this.loading = false;
        this.notificationService.openSnackBar(response.message, 'Close', 'error-snackbar', 10000);
      }
    });
  }

}




