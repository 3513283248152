import {Component, Inject, OnInit} from '@angular/core';
// import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Role } from '../role';
import { UsersService } from '../users.service';
import { NotificationService } from 'app/_services/notification.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../_services';

@Component({
  templateUrl:'change-password-dialog.component.html',
  styleUrls:['change-password-dialog.component.scss'
  ]
})
export class ChangePasswordDialogComponent {

    changePasswordForm: FormGroup;
    roles: Role[];
    loading = false;

    constructor(
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
        private usersService: UsersService,
        private router: Router,
        private authenticationService: AuthenticationService,
        public notificationService: NotificationService,
    ) {}

    ngOnInit() {
        this.changePasswordForm = this.formBuilder.group({
            currentPassword: ['', [Validators.required,Validators.pattern("^((\\+91-?))?[0-9]{9}$")]],
            newPassword: ['', [Validators.required,Validators.pattern("^((\\+91-?))?[0-9]{9}$")]],
            repeatPassword: ['', [Validators.required,Validators.pattern("^((\\+91-?))?[0-9]{9}$")]],
        });
    }

    onCloseDialog() {
        this.dialogRef.close();
    }

    onSubmit(value) {

        console.log("Change Password value");
        console.log(value);

        this.loading = true;
        
        this.usersService.changePassword(value)
          .subscribe(
            data => {
              console.log("Server response: ");
              console.log(data);
              this.loading = false;
              this.notificationService.openSnackBar(data.message, 'Close', 'success-snackbar', 10000);
              this.logout();
            },
            error => {
              this.loading = false;
              console.log(error);
              this.notificationService.openSnackBar(error.message, 'Close', 'error-snackbar', 10000);
            });

      this.dialogRef.close();
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
        this.router.dispose();
    }

}
