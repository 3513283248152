import { Component, OnInit, Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'app/_services';
import { Router } from '@angular/router';
import { NotificationService } from 'app/_services/notification.service';


@Component({
  selector: 'app-otp-dialog',
  templateUrl: './otp-dialog.component.html',
  styleUrls: ['./otp-dialog.component.scss']
})
export class OtpDialogComponent implements OnInit {

  otpForm: FormGroup;
  loading = false;
  emailDestination : String;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<OtpDialogComponent>,
    private authenticationService: AuthenticationService,
    private router: Router,
    public notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.emailDestination = this.data.email;
    this.otpForm = this.formBuilder.group({
      otp: ['', [Validators.required]],
    });
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  onSubmit(value) {

    const data = {
      "otp": value.otp,
      "password": this.generatePassword(4),
      "email": this.data.email
    }
    
    this.authenticationService.resetPassword(data).subscribe((response) => {
      console.log(response)
      if(response.status == "TPDCS"){
        this.loading = false;
        this.router.navigate(['/']);
        this.notificationService.openSnackBar(response.message, 'Close', 'success-snackbar', 10000);
      }
      else{
        this.notificationService.openSnackBar(response.message, 'Close', 'success-snackbar', 10000);
      }
    });
    this.loading = true;
    this.dialogRef.close();
  }

  generatePassword(passwordLength) {
    var numberChars = "0123456789";
    var upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var lowerChars = "abcdefghijklmnopqrstuvwxyz";
    var allChars = numberChars + upperChars + lowerChars;
    var randPasswordArray = Array(passwordLength);
    randPasswordArray[0] = numberChars;
    randPasswordArray[1] = upperChars;
    randPasswordArray[2] = lowerChars;
    randPasswordArray = randPasswordArray.fill(allChars, 3);
    return this.shuffleArray(randPasswordArray.map(function(x) { return x[Math.floor(Math.random() * x.length)] })).join('');
  }
  
  shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

}
