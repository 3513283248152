import {Component, Inject, OnInit} from '@angular/core';
// import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { SmsService } from '../sms.service';
import { NotificationService } from 'app/_services/notification.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  templateUrl:'forward-sms-dialog-component.html',
  styleUrls:['forward-sms-dialog-component.scss'
  ]
})
export class ForwardSmsDialogComponent {

    smsFowardForm: FormGroup;
    loading = false;
    forwardSmsDto: any = {};


    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<ForwardSmsDialogComponent>,
        private smsService: SmsService,
        public notificationService: NotificationService,
    ) {}

    ngOnInit() {

    console.log("this.data");
    console.log(this.data);

        this.smsFowardForm = this.formBuilder.group({
            phone: ['', [Validators.required,Validators.pattern("^((\\+91-?))?[0-9]{9}$")]],
        })
    }

    onCloseDialog() {
        this.dialogRef.close();
    }

    onSubmit(value) {

        console.log("User value ");
        console.log(value);
        this.loading = true;

        this.forwardSmsDto.token = this.data.token;
        this.forwardSmsDto.msisdn = value.phone;

        console.log("this.forwardSmsDto");
        console.log(this.forwardSmsDto);

        this.smsService.forwardSms(this.forwardSmsDto)
          .subscribe(
            data => {
              console.log("Server response: ");
              console.log(data);
              this.loading = false;
              this.notificationService.openSnackBar(data.message, 'Close', 'success-snackbar', 10000);
            },
            error => {
              this.loading = false;
              console.log(error);
              this.notificationService.openSnackBar(error.message, 'Close', 'error-snackbar', 10000);
            });

      this.dialogRef.close();
    }
    
}